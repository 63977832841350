<template>
  <div data-tour="establishment-home">
    <b-alert
      :show="subscriptions.subscription && !isSubscriptionActive"
      variant="danger"
      class="h5"
    >
      <div class="p-3">
        <feather-icon
          size="24"
          icon="AlertTriangleIcon"
          class="mr-2 align-middle"
        />
        Tu cuenta presenta un saldo pendiente de pago, por favor renueva el plan
        o comunícate con nosotros para resolverlo.
      </div>
    </b-alert>
    <b-alert
      :show="subscriptions.subscription && !currentSubscription"
      variant="danger"
      class="h5"
    >
      <div class="p-3">
        <feather-icon
          size="24"
          icon="AlertTriangleIcon"
          class="mr-2 align-middle"
        />
        Tu cuenta presenta no tiene un suscripción activa. Si deseas poder
        realizar ventas, por favor elige un plan o comunícate con nosotros para
        resolverlo. Recuerda que tienes 90 días de prueba en cualquiera de los
        planes.
      </div>
    </b-alert>
   
    <component
      :is="'product-establishment-view'"
    />

    <v-tour
      name="establishmentHomeTour"
      :options="tourOptions"
      :steps="filteredSteps"
    />

    <b-dropdown
      no-caret
      variant="link"
      class="fixed-text help-button"
      toggle-class="text-decoration-none"
      dropleft
    >
      <template #button-content>
        <feather-icon icon="HelpCircleIcon" size="34" />
      </template>
      <!-- <b-dropdown-item @click="handleHelp"> Iniciar tour </b-dropdown-item> -->
      <b-dropdown-item
        target="_blank"
        href="https://wa.me/525529397947?text=Hola,%20necesito%20ayuda"
      >
        Contactar por WhatsApp
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex"
import ServiceEstablishmentView from "./ServiceEstablishmentView.vue"
import ProductEstablishmentView from "./ProductEstablishmentView.vue"
import EstablishmentSupplierView from "./EstablishmentSupplierView.vue"
import subscriptions from "@/store/subscriptions"

export default {
  components: {
    ServiceEstablishmentView,
    ProductEstablishmentView,
    EstablishmentSupplierView,
    subscriptions,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      steps: [
        {
          target: '[data-tour="establishment-home"]',
          content:
            "¡Bienvenido! Aquí encontrarás accesos directos a las funcionalidades principales del establecimiento.",
        },
        {
          target: '[data-tour="proveeduria"]',
          content:
            "Aquí podrás ver el detalle de inventario de los produtos asignados a ti para que los proveeas a tus clientes. Podrás ver el historial del inventario de los últimos 10 días.",
        },
        {
          target: '[data-tour="sucursales"]',
          content:
            "Consulta y administra tus sucursales. Puedes agregar o modificar tiendas, usar el POS, gestionar inventario, ventas en línea, ver reportes y realizar pedidos.",
        },
        {
          target: '[data-tour="clientes"]',
          content:
            "Aquí puedes ver y gestionar tus clientes, revisar sus compras, datos de contacto, saldos y límites de crédito.",
        },
        {
          target: '[data-tour="proveedores"]',
          content:
            "Gestiona tus proveedores y asígnales productos que desees que te surtan.",
        },
        {
          target: '[data-tour="empleados"]',
          content:
            "Administra a tus empleados y sus roles, ya sean cajeros, repartidores, vendedores o administradores.",
        },
        {
          target: '[data-tour="balance"]',
          content:
            "Aquí puedes gestionar el saldo de tu establecimiento para realizar ventas de tiempo aire, pagos de servicios y emitir facturas.",
        },
        {
          target: '[data-tour="facturas"]',
          content:
            "Consulta y timbra facturas. Recuerda que necesitas saldo en tu cuenta Walleat para poder timbrar.",
        },
        {
          target: '[data-tour="stripe"]',
          content:
            "Configura Stripe para aceptar pagos con tarjeta de crédito/débito y transferencias bancarias.",
        },
        {
          target: '[data-tour="planes"]',
          content:
            "Explora los planes disponibles para sacar el máximo provecho de nuestra plataforma.",
        },
        {
          target: '[data-tour="configuracion"]',
          content:
            "Accede a la configuración general de tu establecimiento y personaliza tu e-commerce.",
        },
      ],

      tourOptions: {
        labels: {
          buttonSkip: "Salir",
          buttonPrevious: "Regresar",
          buttonNext: "Siguiente",
          buttonStop: "Finalizar",
        },
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.userData.role_name === "establishment_admin") {
        vm.fetchSubscription(to.params.id)
      }
    })
  },
  created() {
    if (this.userData.role_name !== "establishment_supplier") {
      this.fetchEstablishment(this.$route.params.id).then((res) =>
        localStorage.setItem("establishment", JSON.stringify(res))
      )
    }
  },
  methods: {
    ...mapActions("subscriptions", ["fetchSubscription"]),
    ...mapActions("establishments", ["fetchEstablishment"]),
    handleHelp() {
      this.$tours["establishmentHomeTour"].start()
    },
  },
  computed: {
    ...mapState(["subscriptions"]),
    ...mapGetters("subscriptions", [
      "isSubscriptionActive",
      "currentSubscription",
    ]),
    ...mapGetters("establishments", ["currentEstablishment"]),

    filteredSteps() {
      return this.steps.filter((step) => {
        if (step.target === '[data-tour="proveeduria"]') {
          return this.userData.role_name === "establishment_supplier"
        } else if (
          step.target === '[data-tour="sucursales"]' ||
          step.target === '[data-tour="clientes"]'
        ) {
          return this.userData.role_name !== "establishment_supplier"
        } else if (step.target === '[data-tour="proveedores"]') {
          return (
            this.userData.role_name === "admin" ||
            this.userData.role_name === "establishment_admin"
          )
        } else if (
          step.target === '[data-tour="empleados"]' ||
          step.target === '[data-tour="balance"]' ||
          step.target === '[data-tour="facturas"]' ||
          step.target === '[data-tour="planes"]' ||
          step.target === '[data-tour="configuracion"]'
        ) {
          return (
            this.userData.role_name !== "establishment_presale_clerk" &&
            this.userData.role_name !== "establishment_supplier"
          )
        } else if (step.target === '[data-tour="stripe"]') {
          return this.userData.role_name === "establishment_admin"
        }
        return true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";

.fixed-text {
  font-size: 13px !important;
  color: $primary !important;
}

.help-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
